<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <v-combobox
      :value="value"
      :label="label"
      :error-messages="errors"
      @input="$emit('input', $event)"
      :filter="
        () => {
          return true;
        }
      "
      return-object
      :search-input.sync="searchInp"
      :items="items"
    >
      <template v-slot:item="{ item }">
        <div>
          <v-list-item-title v-text="item.value.value"></v-list-item-title>
          <v-list-item-subtitle
            v-text="item.value.data.inn + ' ' + item.value.data.address.value"
          ></v-list-item-subtitle>
        </div>
      </template>
    </v-combobox>
  </ValidationProvider>
</template>

<script>
import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export default {
  name: "ValidationDataNameField",
  data() {
    return {
      searchInp: "",
      items: []
    };
  },
  props: {
    hideSelected: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    searchFunction: {
      type: Function
    },
    mask: {
      type: String
    },
    type: {
      type: String
    },
    rules: {
      type: String
    },
    validateName: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Object, Number, Array]
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    searchInp(value) {
      !value || this.search(value);
    }
  },
  methods: {
    search(value) {
      Axios.get(
        getQueriedUrl({ url: "/dadata/", query: { payload: value } })
      ).then(response => {
        this.items = response.data.map(el => ({ text: el.value, value: el }));
      });
    }
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
